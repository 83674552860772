import * as React from "react";
import { Link } from "react-router-dom";

export interface IAboutProps {}

export default function About(props: IAboutProps) {
  return (
    <>
      <main>
        <h2>Who are we?</h2>
        <p>That feels like an existential question, don't you think?</p>
      </main>
      <nav>
        <Link to="/">Home</Link>
      </nav>
    </>
  );
}
