import { x } from "@xstyled/emotion";

export function FullScreenTextPlaceholder(props: JSX.IntrinsicAttributes) {
  return (
    <x.div
      h="80vh"
      w="100vw"
      display="flex"
      flexWrap="wrap"
      alignContent="center"
      justifyContent="center"
    >
      <x.div spaceY={0}>
        <x.span
          text="4xl"
          fontWeight="bold"
          letterSpacing="wider"
          display="block"
          pb={2}
          /* TODO: Homogenouse Logo Color with Color Palette.
          Is there because logo is that color */
          color="#242A2D"
        >
          Hundertschaft
        </x.span>
        <x.div divideY={0.1} divideStyle="solid" divideColor="blue-gray-100">
          <x.span
            text="base"
            fontWeight="light"
            display="block"
            lineHeight="lose"
            fontStyle="italic"
            color="blue-gray-300"
            pb={5}
          >
            Hundreds of Technologies <br />
            for Humans, Earth and Art.
          </x.span>
          <x.span
            text="sm"
            fontWeight="light"
            display="block"
            lineHeight="lose"
            fontStyle="italic"
            color="blue-gray-300"
            animation="pulse"
            pt={5}
          >
            Currently in Maintenance
          </x.span>
        </x.div>
      </x.div>
    </x.div>
  );
}
