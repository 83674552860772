// App.js
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Preflight, ThemeProvider, defaultTheme } from "@xstyled/emotion";

import About from "views/About";

import Layout from "containers/Layout";

import { FullScreenTextPlaceholder } from "components/FullScreenTextPlaceholder";

const theme = {
  ...defaultTheme,
  // Customize your theme here asd
};

export default function Views() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Preflight />

        {/* START: Website Routing */}

        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="" element={<FullScreenTextPlaceholder />} />
          </Route>
          <Route path="about" element={<Layout />}>
            <Route path="" element={<About />} />
          </Route>
        </Routes>

        {/* END: Website Routing */}
      </ThemeProvider>
    </BrowserRouter>
  );
}
