import * as React from "react";
import { Outlet } from "react-router-dom";

import { x } from "@xstyled/emotion";

export default function Layout() {
  return (
    <div>
      <x.img
        margin="auto"
        display="block"
        srcSet="logo@2x.png 2x, logo@3x.png 3x"
        src="logo@1x.png"
        w={"4rem"}
        h={"4rem"}
      />
      <Outlet />
    </div>
  );
}
